import { get, post, put, destroy, upload } from 'utils/fetchRequest';
export const GET_PAGES = 'GET_PAGES';

// export const uploadImage = async (data) => {
//     try {
//         const result = await upload('files/comment', data);

//         return result;
//     } catch (err) {
//         return { error: err };
//     }
// };

// export const getMenusList = async (searchText) => {
//     try {
//         const result = await get(`menus${searchText ? `?search=${searchText}` : ''}`);

//         return result;
//     } catch (err) {
//         return { error: err };
//     }
// };

export const getPageContent = async (pageId) => {
    try {
        const result = await get(`pages/${pageId}`);
        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getPageList = async (isHeader, query, dispatch) => {
    try {
        const result = await get(
            `pages?1=1${isHeader === true ? `&isHeader=1` : isHeader === false ? '&isHeader=0' : ''}${query ? query : ''}`
        );

        // dispatch({
        //     type: GET_PAGES,
        //     data: result.data,
        // });

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const getPageAllList = async (query = '') => {
    try {
        const result = await get(`pages/all` + query);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const createPage = async (data) => {
    try {
        const result = await post('pages', data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const updatePage = async (id, data) => {
    try {
        const result = await put(`pages/${id}`, data);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const deletePage = async (id) => {
    try {
        const result = await destroy(`pages/${id}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};

export const updatePagesContent = async (id) => {
    try {
        const result = await put(`pages/pagesContent/${id}`);

        return result;
    } catch (err) {
        return { error: err };
    }
};
