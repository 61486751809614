import React, { useEffect, useState, useContext } from 'react';
import Link from 'next/link';
import { Container, Box, Typography, Grid2 as Grid, TextField } from '@mui/material';
import { SET_ALERT } from 'context/actions/system';
import { createMail } from 'context/actions/mails';
import { useRouter } from 'next/router';
import { MapPin, ChevronRight } from 'react-feather';

import { GlobalContext } from 'context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSquareFacebook,
    faSquareInstagram,
    faFacebookF,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons';

// import { getPageAllList } from 'context/actions/pages';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import CustomSnackBar from 'components/CustomSnackBar';
import { borderRadius, fontSize, height } from '@mui/system';

//functions
import { validateEmail } from 'utils/functions';

let successTimeout = null;

const Footer = () => {
    const { state, dispatch } = useContext(GlobalContext);
    const router = useRouter();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('');
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({
        name: false,
        email: false,
        feedback: false,
    });

    const [snack, setSnack] = useState({ isOpen: false, msg: '', type: 'success' });

    const { t } = useTranslation();

    const [menus, setMenus] = useState([]);

    useEffect(() => {
        getMenus();
    }, []);

    const getMenus = async () => {
        // const datas = await getPageAllList('?isHeader=0');
        // if (datas.status === 'success') setMenus(datas.data);
    };

    const handleCloseSnack = () => {
        setSnack({ ...snack, isOpen: false });
    };

    useEffect(() => {
        successTimeout = setTimeout(() => setSuccess(false), 2500);

        return () => {
            if (successTimeout) clearTimeout(successTimeout);
        };
    }, [success]);

    const handleSendMail = async () => {
        const isValidate = validateEmail(email);
        if (isValidate) {
            const res = await createMail({ email });
            if (res.status === 'success')
                dispatch({
                    type: SET_ALERT,
                    data: { isShow: true, type: 'success', message: t('title.success_register') },
                });
            else
                dispatch({
                    type: SET_ALERT,
                    data: {
                        isShow: true,
                        type: 'warning',
                        message:
                            res.message === 'mail_inserted'
                                ? t('title.already_register')
                                : t('title.error_register'),
                    },
                });
        } else {
            dispatch({
                type: SET_ALERT,
                data: { isShow: true, type: 'warning', message: t('title.not_validate_mail') },
            });
        }
    };

    const onChangeMail = (v) => {
        setEmail(v);
    };

    return (
        <>
            <Container maxWidth={false} disableGutters={true} className="bg-[#035dab] py-10">
                <Container maxWidth={'lg'}>
                    <Grid container spacing={4}>
                        <Grid
                            item="true"
                            size={{ lg: 4, md: 4, sm: 12, xs: 12 }}
                            className="flex flex-col"
                        >
                            <Typography
                                variant={'body14'}
                                className="uppercase text-white font-medium"
                            >
                                {t('title.own_about')}
                            </Typography>
                            <Typography variant={'body12'} className="text-white font-medium mt-4">
                                {t('paragraph.about_us')}{' '}
                                <a
                                    // href="https://example.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'underline',
                                        fontWeight: 'bold',
                                    }}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        if (router.pathname !== '/about') {
                                            router.push('/about');
                                        }
                                    }}
                                >
                                    {t('title.more')}
                                </a>
                            </Typography>
                        </Grid>
                        <Grid
                            item="true"
                            size={{ lg: 4, md: 4, sm: 12, xs: 12 }}
                            className="flex flex-col"
                        >
                        </Grid>
                        <Grid
                            item="true"
                            size={{ lg: 4, md: 4, sm: 12, xs: 12 }}
                            className="flex flex-col"
                        >
                            <Typography
                                variant={'body14'}
                                className="uppercase text-white font-medium"
                            >
                                {t('title.get_information')}
                            </Typography>
                            <Typography variant={'body12'} className="text-white font-medium mt-4">
                                {t('paragraph.mail_to_get_information')}
                            </Typography>
                            <Box className="flex flex-row mt-3 items-center">
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                    size="2x"
                                    className=" text-white cursor-pointer mr-3 hover:text-secondary-100"
                                    onClick={() => {
                                        window.open(
                                            'https://instagram.com',
                                            '_blank',
                                            'noopener,noreferrer'
                                        );
                                    }}
                                    style={{ height: 18 }}
                                />
                                <FontAwesomeIcon
                                    icon={faFacebookF}
                                    size="2x"
                                    className=" text-white cursor-pointer mr-2 hover:text-secondary-100"
                                    onClick={() => {
                                        window.open(
                                            'https://fb.com',
                                            '_blank',
                                            'noopener,noreferrer'
                                        );
                                    }}
                                    // fontSize={7}
                                    style={{ height: 17 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <Container
                maxWidth={false}
                disableGutters={true}
                className="flex justify-center items-center bg-[#04335b] h-8"
            >
                <Container maxWidth={'lg'} className="flex flex-row justify-center">
                    <Typography variant={'body12'} className="text-white">
                        {t('title.right_reserved')}
                    </Typography>
                </Container>
            </Container>

            <CustomSnackBar
                open={snack.isOpen}
                msg={snack.msg}
                handleClose={handleCloseSnack}
                type={snack.type}
            />
        </>
    );
};

export default Footer;
